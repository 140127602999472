<template>
  <!-- eslint-disable max-len -->
  <template-onboarding
    ref="template"
    :steps="onboardingSteps"
    :currentStep="currentOnboardingStep"
  >
    <header class="mb-6">
      <h1 class="font-bold text-xl mb-3 hidden lg:block">Endereço comercial</h1>
      <p class="text-gray-500">
        Caso seu negócio não tenha um endereço próprio, utilize seu endereço residencial.
      </p>
    </header>
    <div class="space-y-5">
      <px-input
        name="cep"
        mask="cep"
        v-model="cep"
        @pxInput="handleInput"
        :maxlength="cepRequiredLength"
        :loading="cepSearching"
        placeholder="Somente números"
      >
        CEP
      </px-input>
      <px-input name="street" v-model="street">Endereço</px-input>
      <px-input name="number" v-model="number" class="sm:w-1/2 w-full">Número</px-input>
      <px-input name="complement" v-model="complement">
        Complemento
        <span class="text-gray-500 font-normal text-sm ml-1">(opcional)</span>
      </px-input>
      <px-input name="district" v-model="district">Bairro</px-input>
      <px-select
        name="stateAddress"
        class="sm:w-1/2 w-full"
        label="Estado"
        placeholder="Selecionar"
        :items="brazilianStates"
        :value="stateAddress"
        @select="stateAddress = $event"
      />
      <px-input name="city" v-model="city">Cidade</px-input>
    </div>
    <footer class="flex justify-end space-x-3 mt-6">
      <px-button
        kind="primary"
        class="w-full md:w-auto"
        :disabled="clicked"
        @click="handleClick"
      >
        <span>Avançar</span>
        <ChevronRightIcon slot="right" size="20"/>
      </px-button>
    </footer>
    <Loader :loading="loading"/>
  </template-onboarding>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import Loader from '@/components/common/Loader.vue';
import searchCEP from '@/lib/searchCEP';
import TemplateOnboarding from './TemplateOnboarding.vue';
import { PxButton, PxInput, PxSelect } from '../../../components/custom';

export default {
  name: 'BusinessAddress',
  metaInfo: {
    title: 'Endereço comercial',
  },
  components: {
    TemplateOnboarding,
    PxButton,
    PxInput,
    PxSelect,
    ChevronRightIcon,
    Loader,
  },
  data: () => ({
    clicked: false,
    currentOnboardingStep: 2,
    cepSearching: false,
    cepRequiredLength: 8,
    loading: false,
    cep: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    stateAddress: '',
    city: '',
  }),
  computed: {
    ...mapState('onboarding', ['brazilianStates', 'onboardingSteps']),
  },
  methods: {
    ...mapMutations('onboarding', ['setBusinessAddress']),
    handleInput() {
      this.cep = this.cep.replace('-', '');
      if (this.cep.length !== this.cepRequiredLength) return null;
      this.cepSearching = true;
      searchCEP(this.cep)
        .then((address) => {
          this.cepSearching = false;
          this.street = address.street;
          this.district = address.neighborhood;
          this.stateAddress = address.state;
          this.city = address.city;
        })
        .catch(() => {
          this.cepSearching = false;
        });
      return null;
    },
    getInvalidInputList() {
      const list = [];
      if (!this.cep || this.cep.length !== this.cepRequiredLength) list.push('cep');
      if (!this.street) list.push('street');
      if (!this.number) list.push('number');
      if (!this.district) list.push('district');
      if (!this.stateAddress) list.push('stateAddress');
      if (!this.city) list.push('city');
      return list;
    },
    async handleClick() {
      const invalidInputList = this.getInvalidInputList();
      if (invalidInputList.length) {
        this.$refs.template.$emit('invalidInput', invalidInputList);
      } else {
        this.clicked = true;
        this.loading = true;

        this.setBusinessAddress({
          cep: this.cep,
          street: this.street,
          number: this.number,
          complement: this.complement,
          district: this.district,
          stateAddress: this.stateAddress,
          city: this.city,
        });
        this.$router.push('/onboarding/informacoes-financeiras');

        this.loading = false;
        this.clicked = false;
      }
    },
  },
};
</script>
