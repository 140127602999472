<!-- eslint-disable max-len -->
<template>
  <div v-if="loading" class="fixed top-0 left-0 right-0 bottom-0 hero-pattern flex flex-col justify-center items-center">
    <div class="w-64 flex flex-col justify-center items-center">
      <div class="w-24 h-24 relative">
        <div class="w-24 h-24 animate-spin border-4 border-white rounded-full absolute top-0 left-0" style="border-right-color: transparent;"></div>
        <div class="w-20 h-20 bg-white bg-opacity-20 rounded-full absolute top-2 left-2 flex flex-col items-center justify-center">
          <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7803 15.8525L21.925 24.0002L23.9992 21.9255L15.8537 13.7778L13.7803 15.8525Z" fill="white"/>
            <path d="M8.14548 10.2216L10.2189 8.14765L2.07343 0L0 2.07397L8.14548 10.2216Z" fill="white"/>
            <path d="M8.14548 13.7778L0 21.9255L2.07343 24.0002L10.2189 15.8525L8.14548 13.7778Z" fill="white"/>
            <path d="M15.8544 10.2216L23.9999 2.07397L21.9257 0L13.7803 8.14765L15.8544 10.2216Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="mt-3 text-white">{{ loadingMessage || 'Carregando...' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loader-component',
  metaInfo: {
    title: 'Carregando...',
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: 'Carregando...',
    },
  },
};
</script>
